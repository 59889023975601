import { Fragment, memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import css from './index.module.scss';
import { IconNode } from '@components/base';
import { SelectLabel, TextField, AutoComplete } from '@components/common';
import { Controller, useFormContext } from 'react-hook-form';
import Images from '@assets/images';
import { fetchCountryList, fetchCityList, fetchStateList } from '@services/util.service';
import notify from '@helpers/toastify-helper';

export interface AddressFieldsProps {
  firstAddressLine: string;
  secondAddressLine: string;
  zipCode: string;
  city: string;
  state: string;
  country: string;
  disabled?: boolean;
  value?: any;
  suffix: string;
}

const AddressFields = (props: AddressFieldsProps) => {
  const {
    firstAddressLine,
    secondAddressLine,
    zipCode,
    city,
    state,
    country,
    value,
    disabled = false,
    suffix
  } = props;
  const { control, setValue, watch } = useFormContext();
  const [countryInfo, setCountryInfo] = useState<any>({
    options: [],
    searchQuery: null,
    disabled: disabled
  });

  const [stateInfo, setStateInfo] = useState<any>({
    options: [],
    searchQuery: null,
    disabled: disabled ? disabled : watch(country) ? false : true
  });

  const [cityInfo, setCityInfo] = useState<any>({
    options: [],
    searchQuery: null,
    disabled: disabled ? disabled : watch(country) ? false : true
  });

  const filterCountryOptions = async (event?: any, param: any = null) => {
    const countryList = await fetchCountryList({ search_text: param });
    if (countryList?.success) {
      setCountryInfo((prevState: any) => ({
        ...prevState,
        options: countryList.data.filter_options,
        searchQuery: param
      }));
    } else {
      setCountryInfo((prevState: any) => ({ ...prevState, options: [], searchQuery: null }));
    }
  };

  useEffect(() => {
    filterCountryOptions();
  }, []);

  const onCountrySelection = (event: any, param: any) => {
    if (param) {
      setValue(country, param);
      setValue(state, null);
      setValue(city, null);
      setCountryInfo((prevState: any) => ({ ...prevState, searchQuery: param.value }));
      setStateInfo((prevState: any) => ({ ...prevState, disabled: false, options: [] }));
      setCityInfo((prevState: any) => ({ ...prevState, disabled: false, options: [] }));
    } else {
      setValue(country, null);
      setValue(state, null);
      setValue(city, null);
      setCountryInfo((prevState: any) => ({ ...prevState, searchQuery: null }));
      setStateInfo((prevState: any) => ({ ...prevState, disabled: true, options: [] }));
      setCityInfo((prevState: any) => ({ ...prevState, disabled: true, options: [] }));
    }
  };

  const filterStateOptions = async (event?: any, param: any = null) => {
    const stateList = await fetchStateList({
      search_text: param,
      country_code: watch(country) ? watch(country)?.value : null
    });
    if (stateList?.success) {
      setStateInfo((prevState: any) => ({
        ...prevState,
        options: stateList.data.filter_options,
        searchQuery: param
      }));
    }
    // else if (stateList?.error) {
    //   notify({
    //     title: 'We have little problem',
    //     message: stateList?.error,
    //     severity: 'error'
    //   });
    // } else {
    //   notify({
    //     title: 'We have little problem',
    //     message: 'Unable to fetch state list',
    //     severity: 'error'
    //   });
    // }
  };

  const onStateSelection = (event: any, param: any) => {
    if (param) {
      setValue(state, param);
      setValue(city, null);
      setStateInfo((prevState: any) => ({ ...prevState, searchQuery: param.label }));
    } else {
      setValue(state, null);
      setStateInfo((prevState: any) => ({ ...prevState, searchQuery: null }));
    }
  };

  const filterCityOptions = async (event?: any, param: any = null) => {
    const cityList = await fetchCityList({
      search_text: param,
      country_code: watch(country) ? watch(country)?.value : null,
      state_name: watch(state) ? watch(state).value : null
    });
    if (cityList?.success) {
      setCityInfo((prevState: any) => ({
        ...prevState,
        options: cityList.data.filter_options,
        searchQuery: param
      }));
    } else {
      setCityInfo((prevState: any) => ({ ...prevState, options: [], searchQuery: null }));
    }
    // else if (cityList?.error) {
    //   notify({
    //     title: 'We have little problem',
    //     message: cityList?.error,
    //     severity: 'error'
    //   });
    // } else {
    //   notify({
    //     title: 'We have little problem',
    //     message: 'Unable to fetch city list',
    //     severity: 'error'
    //   });
    // }
  };

  useEffect(() => {
    if (watch(country)?.value?.length) {
      filterStateOptions();
      filterCityOptions();
    }
  }, [watch(country)]);

  const onCitySelection = (event: any, param: any) => {
    if (param) {
      setValue(city, param);
      setStateInfo((prevState: any) => ({ ...prevState, searchQuery: param.label }));
    } else {
      setValue(city, null);
      setStateInfo((prevState: any) => ({ ...prevState, searchQuery: null }));
    }
  };

  return (
    <Fragment>
      <Controller
        name={firstAddressLine}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            required
            disabled={disabled}
            type="text"
            inputMode="text"
            enterKeyHint="next"
            autoComplete="address-line1"
            label="Address Line 1"
            placeholder="Enter Address Line 1"
            error={fieldState.invalid}
            endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />}
            helperText={fieldState.error?.message}
          />
        )}
      />
      <Controller
        name={secondAddressLine}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            disabled={disabled}
            type="text"
            inputMode="text"
            enterKeyHint="next"
            autoComplete="address-line2"
            label="Address Line 2"
            placeholder="Enter Address Line 2"
            error={fieldState.invalid}
            endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />}
            helperText={fieldState.error?.message}
          />
        )}
      />
      <div className={css.supplierTextFieldWrapper}>
        <Controller
          name={zipCode}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              disabled={disabled}
              type="text"
              inputMode="text"
              enterKeyHint="next"
              autoComplete="postal-code"
              label="Postal / Zip Code"
              placeholder="Enter Postal / Zip Code"
              error={fieldState.invalid}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name={country}
          control={control}
          render={({ field, fieldState }) => (
            <AutoComplete
              {...field}
              required
              label="Country"
              placeholder="Start typing"
              disabled={countryInfo.disabled}
              options={countryInfo.options}
              onInputChange={filterCountryOptions}
              onInputSelection={onCountrySelection}
              keyOption="label"
              // value={countryInfo.searchQuery}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              rootClassName={css.fieldWrapper}
            />
          )}
        />
      </div>
      <div className={css.supplierTextFieldWrapper}>
        <Controller
          name={state}
          control={control}
          render={({ field, fieldState }) => (
            <AutoComplete
              {...field}
              label="State"
              placeholder="Start typing"
              disabled={stateInfo.disabled}
              options={stateInfo.options}
              onInputChange={filterStateOptions}
              onInputSelection={onStateSelection}
              keyOption="label"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              rootClassName={css.fieldWrapper}
            />
          )}
        />
        <Controller
          name={city}
          control={control}
          render={({ field, fieldState }) => (
            <AutoComplete
              {...field}
              required
              label="City"
              placeholder="Start typing"
              disabled={cityInfo.disabled}
              options={cityInfo.options}
              onInputChange={filterCityOptions}
              onInputSelection={onCitySelection}
              keyOption="label"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              rootClassName={css.fieldWrapper}
            />
          )}
        />
      </div>
      <Controller
        name={suffix}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            disabled={disabled}
            type="text"
            inputMode="text"
            enterKeyHint="next"
            autoComplete="address_suffix"
            label="Additional address data"
            placeholder="Start typing"
            error={fieldState.invalid}
            endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />}
            helperText={fieldState.error?.message}
          />
        )}
      />
    </Fragment>
  );
};

AddressFields.propTypes = {
  firstAddressLine: PropTypes.string.isRequired,
  secondAddressLine: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default memo(AddressFields);
